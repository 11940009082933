import React, { useState } from 'react';
import Select from 'react-timezone-select';
import worldImg from "../../assets/icons/world-icn.svg"

function TimezoneSelect() {
    // Set Pakistan's timezone as the default
    const [selectedTimezone, setSelectedTimezone] = useState("Asia/Karachi");

  return (
    <div className='reactTimezone mb-7'>
    <h1 className='text-18 font-bold'>Time zone</h1>
    <img src={worldImg} className='worldImg'/>
      <Select
        value={selectedTimezone}
        onChange={setSelectedTimezone}
      />
    </div>
  );
}

export default TimezoneSelect;
