import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./index.css";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import WelcomeVideo from "../../assets/images/welcome-video.svg";
import ProfileImage from "../../assets/images/defaultDp.webp";
import EditPassword from "../../assets/images/forgot.svg";
import { Pencil } from "../../components/Icons";
import InfoBox from "../../components/InfoBox/SmallInfobox";
import Button from "../../components/EmptyButton";
import session from "../../assets/images/session.svg";
import completeProfileSvg from "../../assets/images/completeProfile.svg";
import timeSlot from "../../assets/images/time slot.svg";
import InfoImg from "../../assets/icons/information.svg";
import { studyModulesData, userData } from "../../redux/selectors";
import { createErrorAlert, getSpecificStudyModules, getUserInfo } from "../../redux/actions";
import VideoPlayer from "../../components/VideoPlayer";
import LazyImage from "../../components/LazyImage";
import StudyMaterials from "../../components/StudyModules/StudyMaterials";
import ResetUserPasswordModal from "../../components/Modal/ResetPassword";
import EditUserInfoModal from "../../components/Modal/EditProfile";
import { apiRequest, getApiKey, getUser } from "../../helpers";
import { Helmet } from "react-helmet";
import { FiAlertTriangle } from "react-icons/fi";
import { Container } from "postcss";
import step1Img from "../../assets/newImages/Group 79.png";
import step2Img from "../../assets/newImages/Group 82.png";
import step3Img from "../../assets/newImages/Group 89.png";

const ProfilePage = (props) => {
  const {
    user_detail,
    // getUserInfo,
    studyModulesData,
    getSpecificStudyModules,
  } = props;

  const dispatch = useDispatch()

  const [recommendation, setRecommendation] = useState([]);
  const [user, setUser] = useState(JSON.parse(getUser()));
  const [isOpenForEdit, setIsOpenForEdit] = useState(false);
  const [isOpenForPassword, setIsOpenForPassword] = useState(false);
  const [showInfo, setShowInfo] = useState(null);
  const [showCancelMessage, setShowCancelMessage] = useState(
    localStorage.getItem("cancelSubscription")
  );
  const [trialExpiredInfo, setTrialExpiredInfo] = useState(false);
  const [completeProfile, setCompleteProfile] = useState(false);
  const [bulletDetail, setBulletDetail] = useState([
    {
      heading:
        "Simple study materials and pre-tested tools helping you to get high grades",
      info: "At SimpleStudying, we built a team of successful law graduates who recently were in your position and achieved 2.1 or First Class in their respective law degrees. We summarised and simplified the overcomplicated information for you. We created simple notes with exam tips, case summaries, sample essays, tutorial videos, quizzes and flashcards <b>all specifically designed for you to get a First Class in the simplest way possible.</b>",
      show: false,
    },
    {
      heading: "Save 738 hours of reading per year compared to textbooks.",
      info: "Save time, study more efficiently. On average, SimpleStudying will save you 738 hours of reading time per year compared to the course textbooks. Thats 2213 LESS pages to read per year!",
      show: false,
    },
    {
      heading:
        "Maximise your chances of a First Class with our personalised support",
      info: "6 tutoring sessions + our study materials = 90% chance of a First Class ",
      show: false,
    },
  ]);

  // useEffect(() => {
  //   let icon = document.getElementById('complete-icon')
  //   if(trialExpiredInfo === true){
  //     if(icon){
  //       icon.style.display = 'none'
  //     }
  //   } else{
  //     if(icon){
  //       icon.style.display = 'block'
  //     }
  //   }
  // })

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    // getUserInfo();
    getSpecificStudyModules();
  }, []);
  useEffect(() => {
    if (user_detail) {
      setUser(user_detail);
    }
    if (studyModulesData) {
      if (studyModulesData.length > 0) {
        let recommend = [];
        studyModulesData.map((item) => {
          recommend.push({
            value: item.title,
            label: item.title,
            item: item,
          });
        });
        setRecommendation(recommend);
      }
    }
  }, [user_detail, studyModulesData]);
  const upgradeMembership = (name) => {
    if (
      name === "GET COVERED FOR THE EXAM PERIOD" ||
      name === "IDEAL IF YOU AIM FOR A FIRST CLASS" ||
      name === "QUICK BOOST OF YOUR GRADES"
    ) {
      history.push({ pathname: `/all-subscriptions` });
    } else if (name === "Basic Subscription") {
      history.push({ pathname: `/subscription` });
    } else {
      history.push({ pathname: `/all-subscriptions` });
    }
  };

  const headingHandle = (subscription) => {
    if (subscription?.subscription_name !== "Basic Subscription") {
      if (subscription?.subscription_name === "QUICK BOOST OF YOUR GRADES") {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING";
      } else if (
        subscription?.subscription_name === "GET COVERED FOR THE EXAM PERIOD"
      ) {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING";
      } else if (
        subscription?.subscription_name === "IDEAL IF YOU AIM FOR A FIRST CLASS"
      ) {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING";
      }
    } else {
      return "Free Subscription";
    }
  };

  const handleInfoSubscription = (user) => {
    if (user?.subscription_name === "Basic Subscription") {
      return "The free subscription lasts forever. However, there is a pop-up that blocks half of the screen on our case summaries and short notes. Also, premium notes that are essential for high grades are not accessible at all. <br/> You can unlock everything with our premium membership. <br/> <b>Our premium membership includes everything you need to get a First-Class law degree!</b><br/> Activate your free trial now!";
    } else if (
      headingHandle(user) ===
        "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING" ||
      headingHandle(user) ===
        "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING" ||
      headingHandle(user) ===
        "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING"
    ) {
      return "This subscription includes everything you need to get high grades without reading big and complicated textbooks! You should use our simple and condensed study materials every day to prepare for your exams for achieving First Class. <br /> <br /> Additionally, one to one tutoring is highly recommended to further improve your chances of a First Class. ";
    } else if (
      headingHandle(user) ===
        "BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING" ||
      headingHandle(user) ===
        "BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING" ||
      headingHandle(user) ===
        "BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING"
    ) {
      return "Saving you thousands of hours in case law research whilst helping you to improve your grades. <br /> <br /> However, this subscription does not give you access to the full notes that contain the crucial information for getting a First Class. Also, it does not give you access to the example essays, tutorial videos, quizzes and flashcards which are all essential content for a First Class. <br /> <br /> If you want unlimited access to all the study materials to maximise your chances of getting a First Class law degree, you should upgrade to the premium for just £10/month.";
    }
  };

  const slugName = (selected) => {
    history.push({
      pathname: `/study-module/${selected?.item?.slug}`,
      state: { selected },
    });
  };

  const mouseEnter = (item) => {
    if (item === 0) {
      document.getElementById(1).style.display = "none";
      document.getElementById(2).style.display = "none";
    } else if (item === 1) {
      document.getElementById(2).style.display = "none";
    }
  };

  const mouseLeave = (item) => {
    if (item === 0) {
      document.getElementById(1).style.display = "block";
      document.getElementById(2).style.display = "block";
    } else if (item === 1) {
      document.getElementById(2).style.display = "block";
    }
  };

  const handleCancelMessage = () => {
    localStorage.removeItem("cancelSubscription");
    setShowCancelMessage(false);
  };

  const handleRoute = (value) => {
    if (value === "tutoring") {
      history.push("/boost-your-grades");
    } else {
      history.push("/");
    }
  };

  const handleZoom = async () => {
    await apiRequest({
      method: 'get',
      url: `accounts/zoom-user/`,
    }).then((res) => {
        dispatch(createErrorAlert(200, '', res.data.detail + ', kindly check you email to approve', 200))
      }).catch((err) => {
        dispatch(createErrorAlert(400, '', err.response.data.response, 400))
      })
  }

  return (
    <div className="bg-white profile-page-container">
      {window.location.pathname === "/profile" ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Simple Studying | Profile</title>
          <meta name="description" content="Profile Description" />
          <link rel="canonical" href="https://simplestudying.com/profile" />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Simple Studying | Profile" />
          <meta property="og:description" content="Profile Description" />
          <meta
            property="og:url"
            content="https://test.simplestudying.com/profile"
          />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
      ) : null}

      <div className="profile-info bg-offWhite">
        {showCancelMessage && (
          <div class="borderMessage mb-4">
            <div class="flex items-center">
              <div class="mr-4 text-primary text-42">
                <FiAlertTriangle />
              </div>
              <div class="text-left px-4">
                Your Premium Subscription will expire soon and you will no
                longer have access to the SimpleStudying materials to maximise
                your chances of getting a First Class law degree. You
                can get your subscription back if you change your mind.
              </div>
              <div class="ml-auto text-primary cursor-pointer">
                <FaTimesCircle onClick={() => handleCancelMessage()} />
              </div>
            </div>
          </div>
        )}

        {user?.user_type === "STUDENT" ? (
          <div className="flex justify-end">
            <p
              className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade"
              onClick={() => upgradeMembership(user?.subscription_name)}
            >
              {!getApiKey()
                ? "JOIN NOW"
                : user?.subscription_name === "Basic Subscription"
                ? "GET UNLIMITED STUDY MATERIALS"
                : user?.subscription_name !== "Basic Subscription"
                ? "VIEW MEMBERSHIP PLAN"
                : "UPGRADE MEMBERSHIP"}
            </p>
          </div>
        ) : null}
        {/*{user?.user_type === 'STUDENT' && user?.subscription_name !== 'Basic Subscription' ?*/}
        {/*    <div className="flex justify-end">*/}
        {/*        <p*/}
        {/*            className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade"*/}
        {/*            onClick={subscriptionList}*/}
        {/*        >*/}
        {/*            Subscriptions*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*    : null}*/}
        {/*{user?.user_type === 'STUDENT' && user?.subscription_name !== 'Basic Subscription' ?*/}
        {/*    <div className="flex justify-end">*/}
        {/*        <p*/}
        {/*            className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade"*/}
        {/*            onClick={manageSubscriptions}*/}
        {/*        >*/}
        {/*            Manage Subscriptions*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*    : null*/}
        {/*}*/}
        <div className="flex profileFlex">
          <div className="flex-1 profileWrap flex">
            <div className="flex name">
              <div className="flex-initial ">
                <div className="image-contanainer">
                  <img
                    src={user?.image !== null ? user?.image : ProfileImage}
                    alt="Profile"
                    className="w-[142px] pr-image"
                  />
                </div>
              </div>
              <div className="flex-initial infoCcontainer">
                <h3 className="student-name">
                {user?.first_name ? user?.first_name : user?.username} {user?.last_name ? user.last_name : ""}                </h3>
                {user?.user_type !== "TEACHER" && (
                  <div className="infoAboutMain">
                    <p className="info-about text-12">{headingHandle(user)}</p>
                    <div className="FreeTrailBtnSec">
                      {user?.user_type !== "TEACHER" && (
                        <div className="toolTipMain ">
                          <img
                            src={InfoImg}
                            alt="info-img"
                            id="freeIcon"
                            className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                            onMouseEnter={() => setTrialExpiredInfo(true)}
                            onMouseLeave={() => setTrialExpiredInfo(false)}
                          />
                          {trialExpiredInfo && (
                            <div className=" tooltipSSec fixing">
                              <InfoBox text={handleInfoSubscription(user)} />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/*{user?.universities?.map((item, index) => (*/}
                {/*    <div className="flex  items-center aboutContainer">*/}
                {/*        <img src={University} alt="Uni"/>*/}
                {/*        <p className="info-about">{item.name}</p>*/}
                {/*    </div>*/}
                {/*))}*/}

                {/*{user?.degrees.map((item, index) => (*/}
                {/*    <div className="flex items-center" key={index}>*/}
                {/*        <img src={Cap} alt="Uni-cap"/>*/}

                {/*        <p className="info-about">{item.name}</p>*/}
                {/*    </div>*/}
                {/*))}*/}
                <div
                  className="flex items-center editContainer"
                  onClick={() => setIsOpenForEdit(true)}
                >
                  <img src={Pencil} alt="Pen" />
                  <p className="text-primary ml-16 font-bold editText">
                    Edit info
                  </p>
                </div>
                <div
                  className="flex items-center editContainer"
                  onClick={() => setIsOpenForPassword(true)}
                >
                  <img src={EditPassword} alt="Pen" style={{ width: 20 }} />
                  <p className="text-primary ml-16 font-bold editText">
                    Change Password
                  </p>
                </div>
                {user?.user_type !== "STUDENT" && (
                  <>
                    <div
                      className="flex items-center editContainer"
                      onClick={() => history.push({ pathname: "/time-slots" })}
                    >
                      <img src={timeSlot} alt="Pen" style={{ width: 20 }} />
                      <p className="text-primary ml-16 font-bold editText">
                        Add Time Slots
                      </p>
                    </div>

                    <div
                      className="flex items-center editContainer"
                      onClick={() => history.push({ pathname: "/sessions" })}
                    >
                      <img src={session} alt="Pen" style={{ width: 20 }} />
                      <p className="text-primary ml-16 font-bold editText">
                        Sessions
                      </p>
                    </div>

                    <div
                      className="flex items-center editContainer"
                      onClick={() => handleZoom()}
                    >
                      <img src={session} alt="Pen" style={{ width: 20 }} />
                      <p className="text-primary ml-16 font-bold editText">
                        Integrate Zoom Account
                      </p>
                    </div>
                  </>
                )}
                {user?.user_type === "STUDENT" && (
                  <div
                    className="flex items-center editContainer"
                    onClick={() => history.push({ pathname: "/user-sessions" })}
                  >
                    <img src={session} alt="Pen" style={{ width: 20 }} />
                    <p className="text-primary ml-16 font-bold editText">
                      My Tutoring Sessions
                    </p>
                  </div>
                )}
                {user?.author && user?.author !== null && (
                  <div
                  className="flex items-center editContainer"
                  onClick={() => history.push({ pathname: "/content-receipts" })}
                >
                  <img src={session} alt="Pen" style={{ width: 20 }} />
                  <p className="text-primary ml-16 font-bold editText">
                    My Receipts
                  </p>
                </div>
                )}
                {/* {user.author !== null && user?.author && (
                  <div
                  className="flex items-center editContainer"
                  onClick={() => history.push({ pathname: "/payment-methods" })}
                >
                  <img src={session} alt="Pen" style={{ width: 20 }} />
                  <p className="text-primary ml-16 font-bold editText">
                    Method of Payment
                  </p>
                </div>
                )} */}

                {/* Complete Your Profile Section Starts */}

                {/* {user?.user_type === "STUDENT" && (
                  <div
                    className="flex items-center editContainer"
                  >
                    <div className="infoAboutMain">
                    <p className="info-about flex ml-0 another-class">
                    <img src={completeProfileSvg} alt="Pen" style={{ width: 20 }} />
                    <p className="text-primary ml-16 font-bold editText" onClick={() => history.push({ pathname: "/signup/step-one" })}>
                      Complete your profile
                    </p>
                    </p>
                    <div className="FreeTrailBtnSec new-set">
                      <div className="toolTipMain ">
                        <img
                          src={InfoImg}
                          alt="info-img"
                          id={'complete-icon'}
                          className=" w-16 md:w-24 custom:w-5 custom:mr-4"
                          onMouseEnter={() => setCompleteProfile(true)}
                          onMouseLeave={() => setCompleteProfile(false)}
                        />
                        {completeProfile && (
                          <div className=" tooltipSSec new-width">
                            <InfoBox
                              text={
                                "Please Complete your profile to help us personalise your account. We will recommend you study materials and tutors based on what you study"
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>
                )} */}

                {/* Complete your profile section ends */}
              </div>
            </div>
          </div>
          <div className="flex-1  image-container">
            <div className="pb-12 custom2:pb-56 mt-14 custom2:flex xl:justify-start custom2:pl-28 lg:pl-28 xl:px-40 relative">
              <div
                className="leafWrap custom2:block absolute custom2:-left-9 custom2:top-6 custom2:w-40 xl:left-1 xl:w-full  z-10"
                style={{ zIndex: 1 }}
              >
                <LazyImage
                  src={
                    "https://simplestudying.com/static/media/leafs%20(2).3e024d8c.webp"
                  }
                  alt="Leafs"
                />
              </div>
              <div
                className="w-full  h-40 custom2:h-80 sm:h-80 xl:h-80 linear-background relative z-20 xl:mt-1"
                style={{ borderRadius: "25px", zIndex: 3 }}
              >
                <div
                  className="w-full h-40 sm:h-80 xl:h-80  custom2:h-80 absolute right-2 top-2 md:right-5 md:top-5 overflow-hidden"
                  style={{ borderRadius: "25px", zIndex: 1 }}
                >
                  <VideoPlayer
                    controls={true}
                    url={"https://youtu.be/sIy7mQHG2Zs"}
                  />
                </div>
              </div>
            </div>
            {/* <p
              style={{
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Premium Subscription Includes
            </p>
            {bulletDetail?.length > 0
              ? bulletDetail?.map((item, index) => {
                  return (
                    <div className="tickBulletSec3 ">
                      <FaCheckCircle />
                      <h5>{item.heading}</h5>
                      <div className="toolTipMain tickBulletSec-infoicon ">
                        <img
                          id={index}
                          src={InfoImg}
                          alt="info-img"
                          className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                          onMouseEnter={() => {
                            setShowInfo(index);
                            mouseEnter(index);
                          }}
                          onMouseLeave={() => {
                            setShowInfo(null);
                            mouseLeave(index);
                          }}
                        />
                        {showInfo === index && (
                          <div className=" tooltipSSec Premium-tip">
                            <InfoBox text={item?.info} />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              : null} */}
            {/* <div className="mt-24 text-primary underline text-24">
              <a
                href="https://simplestudying.com/first-class-students-at-simplestudying/"
                className="text-16"
              >
                See how law students at SimpleStudying get a First Class in
                their exams
              </a>
            </div> */}
          </div>
        </div>
      </div>

      {/* {user?.user_type === "STUDENT" && (
        <div className="recommendations-and-cards bg-white p-2 sm:p-4 lg:px-20 lg:pt-6 xl:px-56 xl:pt-14">
          <div className="w-full neither-container">
            <p className="note">
              Neither our company, nor our products are officially affiliated{" "}
              <br className="break" />
              with any of the universities listed{" "}
            </p>
          </div>
          <div className="mb-50 recom-container">
            <h2 className="text-h4 sm-text-h3 md:text-h2 font-bold text-darkest recom">
              Recommendations
            </h2>
          </div>
          <div className="flex gap:12 lg:gap-30 cards-container flipMain">
            {recommendation.slice(0, 4).map((item, index) => {
              return (
                <div class="flip-card" onClick={() => slugName(item)}>
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <StudyMaterials
                        key={index}
                        image={item?.item?.image}
                        cardTitle={item?.label}
                        slug={item}
                        text={item?.item?.short_description}
                        handleClick={slugName}
                        // price={`$ ${item?.item?.price ? item.item?.price : ' 99.99'}`}
                      />
                    </div>
                    <div class="flip-card-back p-4">
                      <p
                        className="font-bold text-lg cursor-pointer"
                        style={{ fontSize: "22px" }}
                      >
                        {item.label}
                      </p>
                      <div className={`backSubBox flex my-5 flex-wrap gap-3`}>
                        {item?.value?.notes !== 0 ? (
                          <p
                            className="py-1 px-2 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Revision Notes
                          </p>
                        ) : null}
                        {item?.value?.essays !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Model Essays
                          </p>
                        ) : null}
                        {item?.value?.summaries !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Case Summaries
                          </p>
                        ) : null}
                        {item?.value?.quiz !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Quiz
                          </p>
                        ) : null}
                        {item?.value?.flash_cards !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Flash Cards
                          </p>
                        ) : null}
                        {item.tutorial_videos !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Tutorial Videos
                          </p>
                        ) : null}
                      </div>
                      <div
                        className="contentColor"
                        dangerouslySetInnerHTML={{
                          __html: item?.value?.short_description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="mt-50 mb-50 bg-white w-full sm:w-3/4 md:w-2/4 text-center"
            style={{ margin: "10px auto 30px" }}
          >
            <Button className="exploreBtn" onClick={() => history.push("/")}>
              Explore more study materials
            </Button>
          </div>
        </div>
      )} */}

      {/* new cards */}

      {user?.user_type === 'STUDENT' && <div className=" w-80% m-auto">
        <p
          className=" fw-semibold  text-28   md:text-h3 xl:text-36
   w-full sm-md:w-60% text-center m-auto mt-5 mb-7"
        >
          To obtain a <span className="text-yellowish"> First-Class</span> law
          degree, follow these
        </p>
        <div className="flex flex-col sm-md:flex-row flex-wrap md:flex-nowrap gap-6">
          {/* 1 */}
          <div
            className="bg-red rounded-md flex flex-1 flex-col justify-center p-6 items-center pb-16   relative"
            style={{
              boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
              flex: "35%",
              flexBasis: "35%",
            }}
          >
            <p className="text-yellowish text-h3 fw-semibold my-3 ">Step 1</p>
            <div className=" my-9">
              <img src={step1Img} />
            </div>
            <p className="text-h3 fw-semibold text-center my-5 leading-7">
              Study using <span className="text-yellowish">our materials</span>
            </p>
            <ul className="list-disc p-3">
              <li>
                Consistent daily study on our platform with a premium
                subscription should lead to attaining a First-Class law degree.
              </li>
              <li>
                Our study materials were created by First-Class law graduates.
              </li>

              <li>
                Our notes, example essays, flashcards, quizzes, tutorials and
                videos are all specifically designed to help you achieve a
                First-Class in the simplest manner possible.
              </li>
              <li>
                Our materials can{" "}
                <span className="text-yellowish">
                  save you almost 800 hours per year in reading
                </span>{" "}
                compared to textbooks.
              </li>
            </ul>
            <div className="w-90% h-fit my-3 absolute bottom-0 m-auto mx-5">
              <Button
                className="confrmBtn"
                filled
                onClick={() => handleRoute("studyMaterial")}
              >
                Continue
              </Button>
            </div>
          </div>
          {/* 2 */}

          <div
            className="bg-white p-6 rounded-2xl flex flex-1 flex-col pb-16 items-center   relative"
            style={{
              boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
              flex: "35%",
              flexBasis: "35%",
            }}
          >
            <p className="text-yellowish text-h3 fw-semibold text-center my-3">
              Step 2
            </p>
            <div className=" my-9">
              <img src={step2Img} className="m-auto" />
            </div>
            <p className="text-h3 fw-semibold text-center my-5 leading-7">
              Receive personalised{" "}
              <span className="text-yellowish">one-on-one</span> tutoring every
              month{" "}
            </p>
            <ul className="list-disc p-3">
              <li>
                90% of our students who received{" "}
                <span className="text-yellowish">6 hours of tutoring</span> in
                addition to regular study with our premium subscription achieved
                a First-Class.{" "}
              </li>
            </ul>
            <div className="w-90% h-fit  my-3 absolute bottom-0 m-auto ">
              <Button
                className="confrmBtn"
                filled
                onClick={() => handleRoute("tutoring")}
              >
                Continue
              </Button>
            </div>
          </div>

          {/* 3 */}

          <div
            className="bg-white p-6 rounded-2xl flex flex-1 flex-col  items-center pb-16 relative"
            style={{
              boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
              flex: "35%",
              flexBasis: "35%",
            }}
          >
            <p className="text-yellowish text-h3 fw-semibold text-center my-3">
              Step 3
            </p>
            <div className=" my-9">
              <img src={step3Img} className="m-auto" />
            </div>
            <p className="text-h3 fw-semibold text-center my-5 leading-7">
              Attend the free{" "}
              <span className="text-yellowish">revision sessions</span>
            </p>
            <ul className="list-disc p-3">
              <li>
                We conduct free revision sessions closer to the exam period,
                which you should attend. This is an opportunity to{" "}
                <span className="text-yellowish">revise and ask questions</span>{" "}
                to the tutor
              </li>
            </ul>

            <div className="w-90% h-fit my-3 absolute bottom-0  m-auto ">
              <Button
                className="confrmBtn"
                filled
                onClick={() => handleRoute("tutoring")}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>}

      {/* end of new cards */}
      <div className="upgrade-and-video-mobile">
        <div className="flex justify-end">
          <p className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade">
            Upgrade Membership
          </p>
        </div>
        <div className="flex-1 image-container">
          <img src={WelcomeVideo} alt="Video" />
        </div>
      </div>
      <ResetUserPasswordModal
        open={isOpenForPassword}
        setOpen={setIsOpenForPassword}
      />
      <EditUserInfoModal
        open={isOpenForEdit}
        setOpen={setIsOpenForEdit}
        details={user}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  user_detail: userData(state),
  studyModulesData: studyModulesData(state),
});
const mapDispatchToProps = (dispatch) => ({
  // getUserCSRF: () => dispatch(getUserCSRF()),
  getUserInfo: () => dispatch(getUserInfo()),
  getSpecificStudyModules: () => dispatch(getSpecificStudyModules()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
