import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/container";
import Header from "../../components/PlanYourStudyModule/Header";
import { useHistory } from "react-router-dom";
import Button from "../../components/EmptyButton";
import AddIcon from "../../assets/images/addIcon.webp";
import StudyCard from "../../components/StudyCard/DashboardCard";
import { apiRequest, getUser } from "../../helpers";
import FullLoader from "../../components/Loader/FullLoader";
import Context from "../../services/Context";

const ContentCreatorDashboard = () => {
  const history = useHistory();

  const [state, setState] = useState({
    user: JSON.parse(getUser()),
    modules: [],
    loading: false,
    key: ['degree-selected', 'content-module', 'studyModuleId']
  });

  const {value} = useContext(Context)
  const userCurrency = value?.userCurrency || state.user?.user_currency


  useEffect(() => {
    handleGetModules();
    state.key.forEach(function(key) {
      localStorage.removeItem(key);
    });
  }, []);

  const handleGetModules = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    await apiRequest({
      method: "get",
      url: "studies/dashboard/studymodules",
    }).then((res) => {
      if (res.status === 200) {
        let array = [];
        res.data.map((item) => {
          if (
            item.slug !== "macroeconomics" &&
            item.slug !== "microeconomics" &&
            item.slug !== "business" &&
            item.slug !== "marketing" &&
            item.slug !== "our-company" &&
            item.slug !== "useful-articles" &&
            item.slug !== "uncategorized" &&
            item.slug !== "test" &&
            item.slug !== "partners"
          ) {
            if (item.author === state.user.author) {
              array.push(item);
            }
          }
        });
        setState((prev) => ({
          ...prev,
          modules: array,
          loading: false,
        }));
      }
    });
  };
  return (
    <div>
      {state.loading && <FullLoader />}
      <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
        <Header />
        <Container>
          <div className="pt-10 lg:pt-14 flex flex-row items-center">
            <p className="w-full text-center text-h5 md:text-h4 custom2:text-36 font-bold">
              Your content creator dashboard
            </p>
          </div>
          <div className="flex flex-col md:grid md:grid-cols-2 md:gap-12 xl:gap-7 custom:grid custom:grid-cols-3 xl:flex xl:flex-row xl:justify-center md:mt-12">
            {state.modules.length > 0
              ? state.modules.map((item) => {
                  return (
                    <div className="mt-10 xl:mt-0">
                      <StudyCard
                        image={item.image}
                        alt="Statue"
                        cardTitle={item.title}
                        cardDescription={
                          item.is_approved === false
                            ? "Submitted for Review"
                            : "Sales: "
                        }
                        cardSub={item.is_approved === false ? null : item.sales}
                        classNum={userCurrency === "UK" ? "£ " + item.price : "$ " + item.price}
                      />
                    </div>
                  );
                })
              : "No Modules"}
          </div>
          <div
            className="px-4 custom2:px-12 flex flex-row pb-0 pt-16 md:py-16 items-center cursor-pointer justify-center "
            onClick={() => {
              history.push("/content-creator/step-one");
            }}
          >
            <img className="w-3.5 h-auto" src={AddIcon} alt="add" />{" "}
            <span className="font-bold text-16 md:text-body-large text-primary ml-16">
              Add more content
            </span>
          </div>
          <div className="py-20">
            <div className="flex flex-col items-center justify-center md:flex-row md:space-x-10 custom2:space-x-20 md:px-20">
              <div className="w-10/12 custom:w-1/3  h-16  mb-10 md:mb-0">
                <Button filled onClick={() => history.push('/content-receipts')}>
                  <p className="text-center text-14 xl:text-body-large">
                    Check your earnings
                  </p>
                </Button>
              </div>

              <div className="w-10/12 custom2:w-1/3 h-16">
                <Button simple onClick={() => history.push("/content-creator/step-two")}>
                  <p className="text-center text-14 xl:text-body-large">
                    Learn how to sell more content
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ContentCreatorDashboard;
