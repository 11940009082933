import React, { useEffect, useState } from "react";

import Container from "../../components/container";
import StudyMaterialCard from "../../components/StudyMaterialCard";
import Cases from "./Cases";
import Input from "../../components/Input/InputComponent";
import * as Yup from "yup";
import Button from "../../components/EmptyButton";
import { setKey } from "../../helpers";
import { Field, Form, Formik } from "formik";
import { Modal } from "react-responsive-modal";
import GreenCardImage from "../../assets/images/green-card-image.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import InfoImg from "../../assets/icons/information.svg";
import FullLoader from "../../components/Loader/FullLoader";
import { connect, useDispatch } from "react-redux";
import img1 from "../../assets/newImages/Group 1399.png"
import img3 from "../../assets/newImages/Group 1403.png"
import img2 from "../../assets/newImages/Illustration.png"
import GoogleAuthentication from "../../components/SocialAuthentication/GoogleSocialAuth";
import FacebookAuthentication from "../../components/SocialAuthentication/FacebookSocialAuth";
import "slick-carousel/slick/slick.css";
import { saveAs } from "file-saver";
import "slick-carousel/slick/slick-theme.css";
import Slider from "../../components/MainAdminSlider";
import { FaStar } from "react-icons/fa";
import InfoBox from "../../components/InfoBox/SmallInfobox";
import { GetUser } from "../../services";
import {
  caseSummariesData,
  caseSummariesError,
  caseSummariesLoading,
  checkListData,
  studyModulesData,
} from "../../redux/selectors";
import {
  checkListAction,
  createErrorAlert,
  getCaseSummaries,
  userLogin,
  userSignUp,
} from "../../redux/actions";
import apiRequest from "../../helpers/apiRequest";
import { getApiKey } from "../../helpers";
import { postMailChimpAction } from "../../redux/actions/postMailChimpAction";
let value = 0;

const NonRegisteredUsers = (props) => {
  const {
    caseSummariesData,
    caseSummariesError,
    loading,
    getCaseSummaries,
    match,
    location,
    postMailChimp,
    getCheckList,
    checkListData,
    userSignUp,
    userLogin
  } = props;
  let data = location?.state?.selected;
  const [firstTimeCase, setFirstTimeCase] = useState("");
  const [firstTimeTitle, setFirstTimeTitle] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [ogUrl, setOgUrl] = useState("");
  const [description, setDescription] = useState("");
  const [canonical, setCanonical] = useState("");
  const [keywords, setKeywords] = useState("");
  const [robots, setRobots] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const history = useHistory();
  const [checkListDisplay, setCheckListDisplay] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showInfo1, setShowInfo1] = useState(null);
  const [showInfo2, setShowInfo2] = useState(null);
  const [showInfo3, setShowInfo3] = useState(null);
  const [studyModuleState, setStudyModuleState] = useState(
    props?.location?.state?.name ? props?.location?.state?.name : ""
  );
  const [scriptState, setScriptState] = useState(null)
  const [signupModal, setSignupModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [emailCheckList, setEmailCheckList] = useState("");
  const [moduleListPdf, setmoduleListPdf] = useState([
    {
      title: "Criminal Law",
      pdfFile: "",
    },
  ]);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const [testimonials, setTestimonials] = useState([
    {
      text: "The service was efficient and professional. The general feedback in the one-on-one sessions and each tutorial was constructive, detailed, meaningful and generally effective in realising my goals. As a result, I successfully obtained a 1st and three 2.1 in my final assessments. Highly recommended.",
      name: "Ann",
    },
    {
      text: "Nino was very helpful with my studies. I got 1st because of her help! I couldn’t recommend SimpleStudying enough. Thank you so much!",
      name: "Amie",
    },
    {
      text: "I can confirm that since I discovered simple studying, life has almost become perfect for me. I find law module very easy to understand with the help of their tutoring sessions and study materials. I scored over 70 % in my Client Practice module last term.",
      name: "Akinyemi",
    },
  ]);

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });
  const dispatch = useDispatch();
  const params = useParams()

  const forgetPass = () => {
    setLoginModal(false);
    history.push("/forget-password");
  };

  const studyModuleData = async (value) => {
    await apiRequest({
      method: "get",
      url: "studies/studymodules",
    }).then((res) => {
      if (value === 'undefined') {
        siteSearch(res?.data)
      } else if (value === 'defined') {
        for (let i = 0; i < res.data.length; i++) {
          if (
            res.data[i].id === props.location.state.selected.value.study_module.id
          ) {
            setStudyModuleState(res.data[i].title);
            siteSearch(res.data)
          }
        }
      }
    });
  };

  const siteSearch = async (studyModuleRes) => {
    let finalSlug = window.location.pathname.replace("/", "");
    let newSlug = finalSlug.replace("/", "");
    await apiRequest({
      method: "get",
      url: `content/case/search/?case=${newSlug}`,
    }).then((res) => {
      setLoadingState(false);
      for (let i = 0; i < studyModuleRes.length; i++) {
        if (
          parseInt(res?.data[0]?.study_module.id) ===
          parseInt(studyModuleRes[i].id)
        ) {
          setStudyModuleState(studyModuleRes[i].title);
          localStorage.setItem('studyModule', studyModuleRes[i].slug)
          let firstTimeCases = res?.data[0]?.content
            ? res?.data[0]?.content
            : res?.content;
          let firstTimeTitle = res?.data[0]?.title
            ? res?.data[0]?.title
            : res?.title;
          let description = res?.data[0]?.seo_fields?.seo_description
            ? res?.data[0]?.seo_fields?.seo_description
            : res?.seo_fields?.seo_description;
          let ogDescription = res?.data[0]?.seo_fields?.og_description
            ? res?.data[0]?.seo_fields?.og_description
            : res?.seo_fields?.og_description;
          let ogUrl = res?.data[0]?.seo_fields?.og_url
            ? res?.data[0]?.seo_fields?.og_url
            : res?.seo_fields?.og_url;
          let canonical = res?.data[0]?.seo_fields?.seo_canonical_data
            ? res?.data[0]?.seo_fields?.seo_canonical_data
            : res?.seo_fields?.seo_canonical_data;
          let keyword = res?.data[0]?.seo_fields?.keywords
            ? res?.data[0]?.seo_fields?.keywords
            : res?.seo_fields?.keywords;
          let robots =
            res?.data[0]?.seo_fields?.robots === true
              ? "index, follow"
              : "noindex, nofollow";
          setFirstTimeCase(firstTimeCases);
          setFirstTimeTitle(firstTimeTitle);
          setDescription(description);
          setDescription(description);
          setCanonical(canonical);
          setOgUrl(ogUrl);
          setOgDescription(ogDescription);
          setKeywords(keyword);
          setRobots(robots);
        }
      }
    });
  };

  useEffect(() => {
    localStorage.setItem('urlSlug', params.slug1)
  })

  useEffect(() => {
    getCheckList();
    data === undefined ? studyModuleData('undefined') : studyModuleData('defined')
  }, [data]);

  useEffect(() => {
    let text = `{
      "@context": "https://schema.org/",
      "@type": "WebPage",
      "name": "Sign Up to Maximise Your Chances to get a First Class Law Degree",
      "speakable": {
        "@type": "SpeakableSpecification",
        "xPath": [
          "/html/head/title",
          "/html/head/meta[@name='description']/@content"
        ]
      },
      "url": "https://simplestudying.com/"
    }`

    const updatedJson = JSON.parse(text);
    let url = updatedJson.url;
    url = `${url}${params?.slug1}`;
    updatedJson.url = url;

    setScriptState(updatedJson);
  }, [params?.slug1]);

  useEffect(() => { }, [checkListData]);

  const handleUserLogin = async (body) => {
    setLoader(true);
    userLogin(body, history)
  };

  useEffect(() => {
    let isModuleInList
    if (Array.isArray(checkListData)) {
      isModuleInList = checkListData?.some(
        (item) => item.title.toLowerCase().includes(studyModuleState.toLowerCase())
      );
    }
    if (isModuleInList) {
      setCheckListDisplay(isModuleInList);
    } else if (
      studyModuleState === "Principles of Operations Management" || studyModuleState === "Principles Of Operations Management" ||
      studyModuleState === "Organisational Behaviour" ||
      studyModuleState === "Business Economics" ||
      studyModuleState === "Business Tax" ||
      studyModuleState === "Internation financial accounting" ||
      studyModuleState === "Applied Management Accounting" ||
      studyModuleState === "International Financial Reporting" ||
      studyModuleState === "Financial Management" ||
      studyModuleState === "Macroeconomics" ||
      studyModuleState === "Marketing" ||
      studyModuleState === "Business"
    ) {
      setCheckListDisplay(true);
    } else {
      setCheckListDisplay(false);
    }
  }, [checkListData, studyModuleState]);

  useEffect(() => {
    let ribbon = document.getElementById("badge-ribbon");
    if (ribbon !== null || undefined) {
      window.reviewsBadgeRibbon("badge-ribbon", {
        store: "www.simplestudying.com",
        size: "medium",
      });
    }
  }, []);

  const handleSuccess = () => {
    dispatch(createErrorAlert(400, '', 'You have successfully signed up', 400))
  }

  const handleDownloadCheckList = () => {
    let conditionSatisfied = false;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailCheckList.length === 0) {
      setEmailError(true);
      return;
    }
    if (emailRegex.test(emailCheckList)) {
      for (let i = 0; i < checkListData.length; i++) {
        if (checkListData[i].title.toLowerCase().includes(studyModuleState.toLowerCase())) {
          if (!conditionSatisfied) {
            conditionSatisfied = true;

            window.open(checkListData[i].pdf, "_blank")
            saveAs(
              checkListData[i].pdf,
              `${studyModuleState.toLowerCase().replace(" ", "-")}.pdf`
            );
          }
        }
      }
      postMailChimp(emailCheckList, studyModuleState).then((res) => {
        dispatch(createErrorAlert(200, '', res.data.message, 200))
      })
      setEmailError(false);
      setEmailCheckList("");
      if (moduleCheck() === 'secondCheck') {
        handleSuccess()
      }
    } else {
      setEmailError(true);
    }
  };

  const loginRoute = (location) => {
    if (location.pathname === "/") {
      history.push("/login");
    } else {
      setLoginModal(true);
    }
  };

  const signUpRoute = (location) => {
    if (location.pathname === "/") {
      history.push("/signup");
    } else {
      setSignupModal(true);
    }
  };

  const onSignUp = async (values) => {
    setLoader(true);
    if (checkBox === true) {
      userSignUp(values, history)
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };
  const checked = (e) => {
    if (e === true) {
      setCheckBox(true);
    } else {
      setCheckBox(false);
    }
  };

  const moduleCheck = () => {
    if (
      studyModuleState === "International Criminal Law" ||
      studyModuleState === "Media Law" ||
      studyModuleState === "Aviation Law" ||
      studyModuleState === "Criminology" ||
      studyModuleState === "Money Laundering" ||
      studyModuleState === "International Banking Law"
    ) {
      return "firstCheck";
    } else if (
      studyModuleState === "Principles Of Operations Management" || studyModuleState === "Principles of Operations Management" ||
      studyModuleState === "Organisational Behaviour" ||
      studyModuleState === "Business Economics" ||
      studyModuleState === "Business Tax" ||
      studyModuleState === "Internation financial accounting" ||
      studyModuleState === "Applied Management Accounting" ||
      studyModuleState === "International Financial Reporting" ||
      studyModuleState === "Financial Management" ||
      studyModuleState === "Macroeconomics" ||
      studyModuleState === "Marketing" ||
      studyModuleState === "Business"
    ) {
      return "secondCheck";
    }
  };

  return (
    <div>
      {loadingState ? (<FullLoader />) : null}
      {window.location.pathname === "/login" ||
        window.location.pathname === "/signup" ||
        window.location.pathname === "/main" ||
        window.location.pathname === "/privacy-policy" ||
        window.location.pathname === "/terms-conditions" ||
        window.location.pathname === "/all-subscriptions" ||
        window.location.pathname === "/" ||
        window.location.pathname === "/boost-your-grades" ||
        window.location.pathname === "/study-tips" ||
        window.location.pathname === "/about-us" ||
        window.location.pathname === "/our-partners" ||
        window.location.pathname === "/contact-us" ||
        window.location.pathname.includes('content-creator') ||
        window.location.pathname === "/cookies-policy" ? null : (
        <div
          className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat"
          id={"loginDiv"}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {data?.value
                ? data?.value?.seo_fields?.og_title
                : data?.seo_fields?.og_title
                  ? data?.seo_fields?.og_title
                  : firstTimeTitle}
            </title>
            <meta
              name="description"
              content={
                data?.value
                  ? data?.value?.seo_fields?.seo_description
                  : data?.seo_fields?.seo_description
                    ? data?.seo_fields?.seo_description
                    : description
              }
            />
            {/* {data?.value && data?.value?.seo_fields?.seo_canonical_data ? ( */}
              <link
                rel="canonical"
                href={
                  data?.value
                    ? data?.value?.seo_fields?.seo_canonical_data
                    : data?.seo_fields?.seo_canonical_data
                      ? data?.seo_fields?.seo_canonical_data
                      : canonical
                }
              />
            {/* // ) : null} */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:locale:alternate" content="en_gb" />
            <meta property="og:type" content="website" />
            <meta
              name="title"
              content={
                data?.value
                  ? data?.value?.seo_fields?.og_title
                  : data?.seo_fields?.og_title
                    ? data?.seo_fields?.og_title
                    : firstTimeTitle
              }
            />
            <meta
              name="keywords"
              content={
                data?.value
                  ? data?.value?.seo_fields?.keywords
                  : data?.seo_fields?.keywords
                    ? data?.seo_fields?.keywords
                    : keywords
              }
            />
            <meta
              property="og:title"
              content={
                data?.value
                  ? data?.value?.seo_fields?.og_title
                  : data?.seo_fields?.og_title
                    ? data?.seo_fields?.og_title
                    : firstTimeTitle
              }
            />
            <meta
              property="og:description"
              content={
                data?.value
                  ? data?.value?.seo_fields?.og_description
                  : data?.seo_fields?.og_description
                    ? data?.seo_fields?.og_description
                    : ogDescription
              }
            />
            <meta
              property="og:url"
              content={
                data?.value
                  ? data?.value?.seo_fields?.og_url
                  : data?.seo_fields?.og_url
                    ? data?.seo_fields?.og_url
                    : ogUrl
              }
            />
            <script id="script" type="application/ld+json">
              {JSON.stringify(scriptState)}
            </script>
          </Helmet>
          <Container>
            {!getApiKey() && window.location.pathname.includes("-") ? (
              <div className="caseSummarMain">
                <div className="caseSummarSubMain flex justify-between pt-16 flex-wrap pb-40">
                  {/*<div*/}
                  {/*    className="hidden custom:flex flex-none custom:w-60 custom:h-96 xl:w-340 xl:h-628 bg-gray flex justify-center items-center">*/}
                  {/*    <p className="custom:text-h3 font-bold xl:text-36">Google ADS</p>*/}
                  {/*</div>*/}

                  {/* Right Side Bar Optin Start */}

                  {checkListDisplay === true && (
                    <div className="w-full  md:block lg:w-3/12 checkListNowSec">
                      <div className="w-full checkListNowSubSec">
                        <h2 className="mb-3.5 text-center">
                          <span>
                            {moduleCheck() === "firstCheck" ? (
                              <div>
                                <strong>
                                  Aiming for a First Class?</strong> <br /><br />
                                <p>We have created a
                                  well-tested method to help students maximise
                                  their chances of getting high grades. Our study technique guide
                                  contains important informationabout how to prepare for your assessments.
                                </p>
                              </div>
                            ) : moduleCheck() === "secondCheck" ? (
                              <div>
                                <strong>
                                  Do you want to improve your grades?</strong><br /><br /> <p>Our study
                                    materials are designed specifically for
                                    maximising your chances of getting a First
                                    Class. Our materials are  created by top
                                    graduates from UK universities.{" "}</p><br />

                                <p>Sign up to get free simple study materials right in your inbox!</p>
                              </div>
                            ) : (
                              <strong>
                                Want to ensure you get First Class or at least
                                2.1 honours in {studyModuleState}
                              </strong>
                            )}
                          </span>
                        </h2>
                        <p className="mb-3.5 text-center">
                          {moduleCheck() === "firstCheck" ||
                            moduleCheck() === "secondCheck" ? (
                            ""
                          ) : (
                            <span>
                              We’ve created a FREE checklist for you to maximise
                              your chances of getting high grades!
                            </span>
                          )}
                        </p>
                      </div>

                      <form>
                        <div class="relative z-0 w-full mb-6 group">
                          <input
                            type="email"
                            name="emailTwo"
                            style={
                              emailError === true
                                ? { border: "1px solid red" }
                                : { border: "1px solid green" }
                            }
                            className={`emailTwo block py-2.5 px-0 w-full text-sm `}
                            placeholder="Enter your email here "
                            required
                            value={emailCheckList}
                            onChange={(e) => setEmailCheckList(e.target.value)}
                          />
                        </div>

                        <div className="mt-5 mb-7 w-full xl:mt-9 xl:mb-14 mx-auto">
                          <Button
                            type="submit"
                            className="buttonClass"
                            onClick={handleDownloadCheckList}
                          >
                            <p className="font-normal text-15 md:text-small-text xl:text-body-large">
                              {moduleCheck() !== "secondCheck" ? 'Download The Checklist Now' : 'Submit'}
                            </p>
                          </Button>
                        </div>
                      </form>
                    </div>
                  )}

                  {/* Right Side Bar Optin End */}

                  <div className="w-full md:hidden lg:block lg:w-6/12 relative">
                    <div className="flex flex-col items-center">
                      <p className="text-h5 md:text-h3 xl:text-36 font-bold fk text-center">
                        {data?.value?.title
                          ? data?.value?.title
                          : data?.title
                            ? data?.title
                            : firstTimeTitle}
                      </p>
                    </div>
                    <div className="md:px-8 pt-20 xl:pt-10">
                      <Cases
                        text={
                          data?.value?.content
                            ? data?.value?.content
                            : data?.content
                              ? data?.content
                              : firstTimeCase
                        }
                        location={location.pathname}
                      />
                      {/* <div className="onBlurSec px-4 md:px-0 w-full md:w-full py-4 lg:ml-10">
                        <div className="onBlurSubSec bg-white py-4 w-full">
                          <p className="text-15  font-normal mb-3 text-center">
                            One moment before you access the full content..
                          </p>
                          <p className="text-15 font-normal mb-3 text-center">
                            Please{" "}
                            <span
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => {
                                signUpRoute(window.location);
                              }}
                            >
                              sign up
                            </span>{" "}
                            or{" "}
                            <span
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => {
                                loginRoute(window.location);
                              }}
                            >
                              log in
                            </span>{" "}
                            to read full Case Summary
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  {/* for mob checklist sec  */}
                  {/* <div className="w-full block md:hidden md:w-4/12 lg:w-3/12 checkListNowSec">
                    <div className="w-full checkListNowSubSec">
                      <h2 className="mb-3.5 text-center">
                        <span>
                          {moduleCheck() === 'firstCheck' ? <strong>
                          Aiming for a First Class?
                          We have created a well-tested method to help students maximise their chances of getting high grades. Our study technique guide contains important information about how to prepare for your assessments.
                          </strong> : moduleCheck() === 'secondCheck' ? <strong>Do you want to improve your grades?
                              Our study materials are designed specifically for maximising your chances of getting a First Class. Our materials are  created by top graduates from UK universities. </strong> : <strong>Want to ensure you get First Class or at least 2.1
                            honours in {studyModuleState}</strong>}
                          
                        </span>
                      </h2>
                      <p className="mb-3.5 text-center">
                      {moduleCheck() === 'firstCheck' || moduleCheck() === 'secondCheck' ? '' :<span>
                          We’ve created a FREE checklist for you to maximise
                          your chances of getting high grades!
                        </span>}
                        
                      </p>
                    </div>
                    
<form>
  <div class="relative z-0 w-full mb-6 group">
      <input type="email" name="emailTwo"  class="emailTwo block py-2.5 px-0 w-full text-sm " placeholder="Enter your email here " required />
   
  </div>




                    <div className="mt-5 mb-7 w-full xl:mt-9 xl:mb-14 mx-auto">
                      <Button type="submit"  className="buttonClass">
                        <p className="font-normal text-15 md:text-small-text xl:text-body-large">
                          Download The Checklist Now
                        </p>
                      </Button>
                    </div>
                    </form>
                  </div> */}
                  {/* for mob checklist sec end */}

                  {/* Sign Up Modal */}

                  <div className="w-full md:hidden lg:block lg:w-3/12  h-1/3">
                    {/*<div>*/}
                    {/*    <StudyMaterialCard*/}
                    {/*        img={PurpleCardImage}*/}
                    {/*        imageClass="w-32 h-32 xl:w-36 xl:h-36 pt-14"*/}
                    {/*        alt="Purple card image"*/}
                    {/*        color="bg-purple"*/}
                    {/*        text={<p*/}
                    {/*            className="text-center text-16 xl:text-h5 xl:font-bold text-white pt-14 xl:pt-10">Sell*/}
                    {/*            your Study Materials <br/>to Generate Value from your <br*/}
                    {/*                className="lg:hidden"/> Knowledge</p>}*/}
                    {/*        description={<p*/}
                    {/*            className="text-small-text xl:text-body-large text-white text-center">Publish your*/}
                    {/*            notes and essays <br className=""/> and get recurring monthly <br/> revenues!</p>}/>*/}
                    {/*</div>*/}
                    <div className="pt-7 greenCardsec  green-card-sec">
                      <StudyMaterialCard
                        img={GreenCardImage}
                        imageClass="w-32 h-32 xl:w-36 xl:h-36 pt-6 md:pt-10 lg:pt-14"
                        alt="Green card image"
                        color="bg-offGreen"
                        data={data?.content}
                        text={
                          <p className="text-center text-16 xl:text-h5 xlfont-bold text-white pt-14 xl:pt-10">
                            Get Study Materials and Tutoring <br /> to Improve
                            your Grades
                          </p>
                        }
                        description={
                          <p className="text-small-text xl:text-body-large text-white">
                            <div className="flex relative caseSumGreenMain">
                              <img
                                src={InfoImg}
                                alt="info-img"
                                className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                onMouseEnter={() => {
                                  setShowInfo1(true);
                                }}
                                onMouseLeave={() => {
                                  setShowInfo1(false);
                                }}
                              />
                              {showInfo1 && (
                                <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                  <InfoBox
                                    text={
                                      "<p>At SimpleStudying, we built a team of successful law graduates who recently were in your position and achieved 2.1 or First Class in their respective law degrees. We summarised and simplified the overcomplicated information for you. We created simple notes with exam tips, case summaries, sample essays, tutorial videos, quizzes and flashcards <b>all specifically designed for you to get a First Class in the simplest way possible.</b></p>"
                                    }
                                  />
                                </div>
                              )}
                              Studying Materials and pre-tested tools helping
                              you to get high grades
                            </div>
                            <div className="flex relative caseSumGreenMain">
                              <img
                                src={InfoImg}
                                alt="info-img"
                                className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                onMouseEnter={() => {
                                  setShowInfo2(true);
                                }}
                                onMouseLeave={() => {
                                  setShowInfo2(false);
                                }}
                              />
                              {showInfo2 && (
                                <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                  <InfoBox
                                    text={
                                      "<p>Save time, study more efficiently. On average, SimpleStudying will save you 738 hours of reading time per year compared to the course textbooks. That’s 2213 LESS pages to read per year!</p>"
                                    }
                                  />
                                </div>
                              )}
                              Save 738 hours of reading per year compared to
                              textbooks
                            </div>
                            <div className="flex relative caseSumGreenMain">
                              <img
                                src={InfoImg}
                                alt="info-img"
                                className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                onMouseEnter={() => {
                                  setShowInfo3(true);
                                }}
                                onMouseLeave={() => {
                                  setShowInfo3(false);
                                }}
                              />
                              {showInfo3 && (
                                <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                  <InfoBox
                                    text={
                                      "<p>6 tutoring sessions + our study materials = 90% chance of a First Class</p>"
                                    }
                                  />
                                </div>
                              )}
                              Maximise your chances of a First Class with our
                              personalised support
                            </div>
                          </p>
                        }
                      />

                      <div className=" testiSLiderWrapper bg-white bg-contain bg-no-repeat pt-10 lg:pt-20 px-4 md:px-6 xl:px-12">
                        <div className="testiSliderMain">
                          {/* <p className="testiSliderHeading mb-12 text-h5 md:text-h4 font-bold text-darkest xl:text-h3">
                            Testimonials
                          </p> */}
                          <div id="badge-ribbon" class="hidden-ribbon"></div>
                        </div>

                        {/* <p className="text-center mt-12 testiFooterText">
                          More testimonials you can find{" "}
                          <a href="https://www.reviews.co.uk/company-reviews/store/www.simplestudying.com">
                            here
                          </a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* for tab case right view */}
                  <div className="forTabCaseRightView tab-case-first hidden md:w-8/12 md:block lg:hidden">
                    <div className="w-full relative">
                      <div className="flex flex-col items-center">
                        <p className="text-h5 md:text-h3 xl:text-36 font-bold fk text-center">
                          {data?.value?.title
                            ? data?.value?.title
                            : data?.title
                              ? data?.title
                              : firstTimeTitle}
                        </p>
                      </div>
                      <div className="md:px-8 pt-20 xl:pt-10">
                        <Cases
                          text={
                            data?.value?.content
                              ? data?.value?.content
                              : data?.content
                                ? data?.content
                                : firstTimeCase
                          }
                          location={location.pathname}
                        />
                        {/* <div className="onBlurSec px-4 md:px-0 w-full md:w-full py-4 lg:ml-10">
                          <div className="onBlurSubSec bg-white py-4 w-full">
                            <p className="text-15  font-normal mb-3 text-center">
                              One moment before you access the full content..
                            </p>
                            <p className="text-15 font-normal mb-3 text-center">
                              Please{" "}
                              <span
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                  signUpRoute(window.location);
                                }}
                              >
                                sign up
                              </span>{" "}
                              or{" "}
                              <span
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                  loginRoute(window.location);
                                }}
                              >
                                log in
                              </span>{" "}
                              to read full Case Summary
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="signup-Modal">
                      <Modal
                        open={signupModal}
                        onClose={() => setSignupModal(false)}
                        focusTrapped={false}
                        center
                        classNames={{
                          overlay: 'custom-overlay-class',
                          modal: 'react-modal-class1',
                        }}
                      >
                        {/* <GoogleAuthentication />
                    <FacebookAuthentication /> */}
                        {/* <div className="flex flex-row justify-center items-center px-2 lg:px-6 my-2 lg:my-7">
                          <div className="border border-ligthBorderGrey w-2/5"></div>
                      <p className="px-3.5 text-darkest font-body-large ">or</p>
                      <div className="border border-ligthBorderGrey w-2/5"></div>
                        </div> */}
                        <div className="flex">
                          <div className="hidden md:block">

                            <p className='text-body-large font-bold text-left md:text-h3 xl:text-36  mr-1'>
                              Join the movement of <span className="text-yellowish">26K savvy SimpleStudying members</span> already empowered - and you're next
                            </p>

                            <p className=" text-body-small text-left pr-2 lg:pt-14 md:text-h5 xl:text-36 xl:pt-14"
                            >
                              Discover the secret to success as you become part of our growing community.
                            </p>
                            <p className="text-body-small text-left pr-2 lg:pt-14 md:text-h5 xl:text-36 xl:pt-14"
                            >
                              Find simple and concise study resources created by top graduates who took the same courses as you at US and UK universities</p>
                            <div className='flex justify-around mt-10 items-center flex-col lg:flex-row'>
                              {/* <div className=' text-center'>
                                <div className='w-116 h-100'>
                                  <img src={img1} className='m-auto mt-3 w-full h-full' />
                                </div>
                                <p className='  fw-bold text-42 text-yellowish '>35+</p>
                                <p>Study Modules<br />All main areas of English law</p>
                              </div> */}
                              <div className=' text-center'>
                                <div className='w-100 h-100'>
                                  <img src={img2} className='m-auto mt-3 w-full h-full' />
                                </div>
                                <p className='fw-bold text-42  text-yellowish'>366K+</p>
                                <p>Active Users<br />in 2023</p>
                              </div>
                              <div className=' text-center'>
                                <div className='w-100 h-100'>
                                  <img src={img3} className='m-auto mt-3 w-full h-full' />
                                </div>
                                <p className='fw-bold text-42  text-yellowish'>1600+</p>
                                <p>Hours Tutoring <br />Delivered</p>
                              </div>
                            </div>

                          </div>
                          <div>

                            <Formik
                              initialValues={{
                                password1: "",
                                username: "",
                                email: "",
                                password2: "",
                              }}
                              validationSchema={validateSchemaSignup}
                              onSubmit={(values) => onSignUp(values)}
                            >
                              {({ isValid, handleSubmit, values }) => (
                                <Form>
                                  <form onSubmit={(e) => e.preventDefault()}>
                                    <div className="flex flex-col text-darkest items-center">
                                      <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                                        Sign up to <span className="text-yellowish">SimpleStudying</span>
                                      </p>                                      <div className="w-full mb-3 md:mb-9 ">
                                        <p className="mb-8">Email</p>
                                        <Field
                                          name="email"
                                          as={Input}
                                          placeholder="Enter your email"
                                          type="text"
                                        />
                                      </div>

                                      <div className="w-full mb-3 md:mb-9 ">
                                        <p className="mb-8">Username</p>
                                        <Field
                                          name="username"
                                          as={Input}
                                          placeholder="Enter your username"
                                          type="text"
                                        />
                                      </div>

                                      <div className="w-full ">
                                        <p className="mb-8">Password</p>
                                        <Field
                                          name="password1"
                                          as={Input}
                                          placeholder="Enter your password"
                                          type={showPassword ? "text" : "password"}
                                          setShowPassword={setShowPassword}
                                          showPassword={showPassword}
                                          inputType="password"
                                        />
                                        <p className="text-small text-darkGrey2 my-8">
                                          6+ characters, 1 capital letter{" "}
                                        </p>
                                      </div>
                                      <div className="w-full mb-10">
                                        <p className="mb-8">Confirm password</p>
                                        <Field
                                          name="password2"
                                          as={Input}
                                          placeholder="Enter your confirm password"
                                          type={
                                            showConfirmPassword ? "text" : "password"
                                          }
                                          setShowPassword={setShowConfirmPassword}
                                          showPassword={showConfirmPassword}
                                          inputType="password"
                                        />
                                      </div>
                                      <div
                                        className="w-full mb-10"
                                        style={{ display: "flex" }}
                                      >
                                        <label className="condContainer">
                                          {" "}
                                          I agree to{" "}
                                          <a
                                            style={{
                                              color: "#cc6828",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              history.push("/terms-conditions");
                                            }}
                                          >
                                            Terms and Conditions
                                          </a>
                                          ,{" "}
                                          <a
                                            style={{
                                              color: "#cc6828",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              history.push("/privacy-policy");
                                            }}
                                          >
                                            Privacy Policy
                                          </a>{" "}
                                          and{" "}
                                          <a
                                            style={{
                                              color: "#cc6828",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              history.push("/cookies-policy");
                                            }}
                                          >
                                            Cookies Policy
                                          </a>{" "}
                                          of Simple Studying{" "}
                                          <input
                                            id="condBox"
                                            onClick={(e) => checked(e.target.checked)}
                                            type="checkbox"
                                            className="default:ring-2 text-primary mr-3 checkBoxOne"
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="pt-4 flex justify-center mb-10">
                                      <div className="w-full h-51">
                                        <Button
                                          onClick={handleSubmit}
                                          text="Sign up for free"
                                          filled
                                          className="buttonClass"
                                          disabled={
                                            values.username === "" ||
                                            values.password1 === "" ||
                                            values.password2 === "" ||
                                            values.email === "" ||
                                            !isValid ||
                                            values.checked === "false"
                                          }
                                          loading={loader}
                                        />
                                      </div>
                                    </div>
                                  </form>
                                </Form>
                              )}
                            </Formik>
                          </div></div>
                        <p className="font-normal text-darkest font-body-large text-center">
                          Already have an account?{" "}
                          <a
                            className="font-bold text-primary cursor-pointer"
                            onClick={() => {
                              setLoginModal(true);
                              setSignupModal(false);
                            }}
                          >
                            {"  "}Log in
                          </a>
                        </p>
                      </Modal>
                    </div>
                    <Modal
                      open={loginModal}
                      onClose={() => setLoginModal(false)}
                      focusTrapped={false}
                      center
                      classNames={{
                        overlay: 'custom-overlay-class',
                        modal: 'react-modal-class1',
                      }}
                    >
                      {/* <GoogleAuthentication />
                    <FacebookAuthentication /> */}
                      {/* <div className="flex flex-row justify-center items-center px-2 lg:px-6 my-2 lg:my-7">
                        <div className="border border-ligthBorderGrey w-2/5"></div>
                      <p className="px-3.5 text-darkest font-body-large ">or</p>
                      <div className="border border-ligthBorderGrey w-2/5"></div>
                      </div> */}
                      <div className="flex">
                        <div className="  flex-basis-60 w-full hidden md:block">
                          <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                            Join the movement of{" "}
                            <span className="text-yellowish">26K savvy SimpleStudying members</span> already
                            empowered - and you're next
                          </p>

                          <p className='text-body-large pr-2 font-bold text-left md:text-h3 xl:text-36  mr-1'>
                            Join the movement of <span className="text-yellowish">26K savvy SimpleStudying members</span> already empowered - and you're next
                          </p>

                          <p className=" text-body-small text-left  md:text-h5 xl:text-36 "
                          >
                            Discover the secret to success as you become part of our growing community.
                          </p>
                          <p className="text-body-small text-left pt-6 md:text-h5 xl:text-36"
                          >
                            Find simple and concise study resources created by top graduates who took the same courses as you at US and UK universities</p>
                          <div className='flex justify-around mt-10 items-center flex-col lg:flex-row'>
                            {/* <div className=' text-center'>
                              <div className='w-150 h-150'>
                                <img src={img1} className='m-auto mt-3 w-full h-full' />
                              </div>
                              <p className='  fw-bold text-42 text-yellowish '>35+</p>
                              <p>Study Modules<br />All main areas of English law</p>
                            </div> */}
                            <div className=' text-center'>
                              <div className='w-150 h-150'>
                                <img src={img2} className='m-auto mt-3 w-full h-full' />
                              </div>
                              <p className='fw-bold text-42  text-yellowish'>366K+</p>
                              <p>Active Users<br />in 2023</p>
                            </div>
                            <div className=' text-center'>
                              <div className='w-150 h-150'>
                                <img src={img3} className='m-auto mt-3 w-full h-full' />
                              </div>
                              <p className='fw-bold text-42  text-yellowish'>1600+</p>
                              <p>Hours Tutoring <br />Delivered</p>
                            </div>
                          </div>

                        </div>
                        <div className="w-1/2">
                          <Formik
                            initialValues={{
                              password: "",
                              username: "",
                            }}
                            validationSchema={validateSchema}
                            onSubmit={(values) => handleUserLogin(values)}
                          >
                            {({ isValid, handleSubmit, values }) => (
                              <Form>
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <div className="loginInputMain flex flex-col text-darkest items-center">
                                    <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                                      Sign in to <span className="text-yellowish">SimpleStudying</span>
                                    </p>
                                    <div className="w-full mb-3 custom2::mb-9 ">
                                      <p className="mb-8">Username or Email</p>
                                      <Field
                                        name="username"
                                        as={Input}
                                        autoComplete="off"
                                        placeholder="Enter your username or email"
                                        type="text"
                                      />
                                    </div>
                                    <div className="w-full  mb-3 custom2:mb-9">
                                      <p className="mb-8 ">Password</p>
                                      <Field
                                        name="password"
                                        as={Input}
                                        placeholder="Enter your password"
                                        type={showPassword ? "text" : "password"}
                                        setShowPassword={setShowPassword}
                                        showPassword={showPassword}
                                        inputType="password"
                                        autoComplete="off"
                                      />
                                    </div>
                                    <Link
                                      className="mb-8 forgetText "
                                      onClick={forgetPass}
                                    >
                                      Forget Password?
                                    </Link>
                                  </div>
                                  <div className="pt-4 flex justify-center mb-10">
                                    <div className="w-full h:34 md:h-42 lg:h-51">
                                      <Button
                                        onClick={handleSubmit}
                                        text="Log in"
                                        filled
                                        className="buttonClass"
                                        disabled={
                                          values.username === "" ||
                                          values.password === "" ||
                                          !isValid
                                        }
                                        loading={false}
                                      />
                                    </div>
                                  </div>
                                </form>
                              </Form>
                            )}
                          </Formik>
                          <p className="loginBottomText font-normal text-darkest font-body-large text-center">
                            You don't have an account yet?{" "}
                            <a
                              className="font-bold text-primary cursor-pointer"
                              onClick={() => {
                                setSignupModal(true);
                                setLoginModal(false);
                              }}
                            >
                              {"  "}Sign up
                            </a>
                          </p>
                        </div>
                      </div>
                    </Modal>
                    <div className="px:8 w-full md:w-12/12 lg:w-3/12  h-1/3">
                      <div className="pt-7 greenCardsec green-card-sec">
                        <StudyMaterialCard
                          img={GreenCardImage}
                          imageClass="w-32 h-32 xl:w-36 xl:h-36 pt-6 md:pt-10 lg:pt-14"
                          alt="Green card image"
                          color="bg-offGreen"
                          data={data?.content}
                          text={
                            <p className="text-center text-16 xl:text-h5 xlfont-bold text-white pt-14 xl:pt-10">
                              Get Study Materials and Tutoring <br /> to Improve
                              your Grades
                            </p>
                          }
                          description={
                            <p className="text-small-text xl:text-body-large text-white">
                              <div className="flex relative caseSumGreenMain">
                                <img
                                  src={InfoImg}
                                  alt="info-img"
                                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                  onMouseEnter={() => {
                                    setShowInfo1(true);
                                  }}
                                  onMouseLeave={() => {
                                    setShowInfo1(false);
                                  }}
                                />
                                {showInfo1 && (
                                  <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                    <InfoBox
                                      text={
                                        "<p>The free trial is automatically activated for everyone who signs up on our platform. This is for you to try our premium account and then make an informed decision if you want to keep your premium account. You dont need to cancel the trial. Trial will automatically cancel in 2 days. </p>"
                                      }
                                    />
                                  </div>
                                )}
                                Studying Materials and pre-tested tools helping
                                you to get high grades
                              </div>
                              <div className="flex relative caseSumGreenMain">
                                <img
                                  src={InfoImg}
                                  alt="info-img"
                                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                  onMouseEnter={() => {
                                    setShowInfo2(true);
                                  }}
                                  onMouseLeave={() => {
                                    setShowInfo2(false);
                                  }}
                                />
                                {showInfo2 && (
                                  <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                    <InfoBox
                                      text={
                                        "<p>The free trial is automatically activated for everyone who signs up on our platform. This is for you to try our premium account and then make an informed decision if you want to keep your premium account. You dont need to cancel the trial. Trial will automatically cancel in 2 days. </p>"
                                      }
                                    />
                                  </div>
                                )}
                                Save 738 hours of reading per year compared to
                                textbooks
                              </div>
                              <div className="flex relative caseSumGreenMain">
                                <img
                                  src={InfoImg}
                                  alt="info-img"
                                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                                  onMouseEnter={() => {
                                    setShowInfo3(true);
                                  }}
                                  onMouseLeave={() => {
                                    setShowInfo3(false);
                                  }}
                                />
                                {showInfo3 && (
                                  <div className=" caseSumTTSubSec tooltipSSec absolute z-10 top-12  -left-6 h-auto">
                                    <InfoBox
                                      text={
                                        "<p>The free trial is automatically activated for everyone who signs up on our platform. This is for you to try our premium account and then make an informed decision if you want to keep your premium account. You dont need to cancel the trial. Trial will automatically cancel in 2 days. </p>"
                                      }
                                    />
                                  </div>
                                )}
                                Maximise your chances of a First Class with our
                                personalised support
                              </div>
                            </p>
                          }
                        />

                        <div className=" testiSLiderWrapper bg-white bg-contain bg-no-repeat pt-10 lg:pt-20 px-4 md:px-6 xl:px-12">
                          <div className="testiSliderMain">
                            <p className="testiSliderHeading mb-12 text-h5 md:text-h4 font-bold text-darkest xl:text-h3">
                              Testimonials
                            </p>
                            <Slider
                              responsive={{
                                superLargeDesktop: {
                                  // the naming can be any, depends on you.
                                  breakpoint: { max: 4000, min: 1919 },
                                  items: 1,
                                },
                                desktop: {
                                  breakpoint: { max: 1919, min: 1500 },
                                  items: 1,
                                },
                                ipadPro: {
                                  breakpoint: { max: 1500, min: 1024 },
                                  items: 1,
                                },
                                tablet: {
                                  breakpoint: { max: 1023, min: 768 },
                                  items: 1,
                                },
                                tabletBelow: {
                                  breakpoint: { max: 767, min: 465 },
                                  items: 1,
                                },
                                mobile: {
                                  breakpoint: { max: 464, min: 0 },
                                  items: 1,
                                },
                              }}
                            >
                              {testimonials?.map((item, index) => {
                                return (
                                  <div className="slide-content sm:w-4/4 md:w-2/2 mx-auto px-8">
                                    <div class="rating-stars gap-3 flex justify-center">
                                      <FaStar />
                                      <FaStar />
                                      <FaStar />
                                      <FaStar />
                                      <FaStar />
                                    </div>
                                    <p
                                      className="text-center my-2 testiContentP"
                                      dangerouslySetInnerHTML={{
                                        __html: item?.text,
                                      }}
                                    ></p>
                                    <h5 className="text-center my-3">
                                      {item?.name}
                                    </h5>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>

                          <p className="text-center mt-12 testiFooterText">
                            More testimonials you can find{" "}
                            <a href="https://www.reviews.co.uk/company-reviews/store/www.simplestudying.com">
                              here
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* for tab case right view end  */}
                </div>
              </div>
            ) : null}
          </Container>
        </div>
      )}


    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: caseSummariesLoading(state),
  caseSummariesError: caseSummariesError(state),
  caseSummariesData: caseSummariesData(state),
  studyModulesData: studyModulesData(state),
  checkListData: checkListData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCaseSummaries: (page, title, slug) =>
    dispatch(getCaseSummaries(page, title, slug)),
  getCheckList: () => dispatch(checkListAction()),
  postMailChimp: (email, study) => dispatch(postMailChimpAction(email, study)),
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NonRegisteredUsers);
