import React, { useContext, useEffect, useState } from 'react'
import Container from "../../components/container";
import Stepper from "../../components/SignUpStepper";
import { useHistory, useLocation } from 'react-router-dom';
import pkgImg from "../../assets/images/packageImg.png";
import Vector from "../../assets/images/Vector.png";
import InfoImg from "../../assets/icons/information.svg";
import FullLoader from "../../components/Loader/FullLoader";
import Context from "../../services/Context";
import { getApiKey } from "../../helpers";
import Select from "../../components/Select";
import TutorCard from './TutorCard';
import { connect } from 'react-redux';
import { getSpecificStudyModules, getTutor } from '../../redux/actions';
import { studyModulesData, tutorData } from '../../redux/selectors';

const TutoringSelection = ({ tutor, getTutor, studyModulesData }) => {
    const history = useHistory()
    const location = useLocation()
    const { value, updateValue } = useContext(Context);

    const { selectedPackages, selectedModule } = value
    const [state, setState] = useState({
        packages: null,
        selectedTutor: null,
        showInfo: false,
        loading: false,
        loginModal: false,
        signUpModal: false,
        loader: false,
        modules: [],
        sortedData: [],
        selectedModule: {},
    });


    useEffect(() => {
        getTutor(location?.state?.selectedPackage)
    }, [])

    useEffect(() => {
        if (studyModulesData) {
            if (studyModulesData.length > 0) {
                let recom = [];
                studyModulesData.map((item) => {
                    if (
                        item.slug !== "macroeconomics" &&
                        item.slug !== "microeconomics" &&
                        item.slug !== "business" &&
                        item.slug !== "marketing" &&
                        item.slug !== "our-company" &&
                        item.slug !== "useful-articles" &&
                        item.slug !== "uncategorized" &&
                        item.slug !== "test" &&
                        item.slug !== "partners"
                    ) {
                        recom.push({
                            value: item,
                            label: item.title,
                        });
                    }
                });
                // eslint-disable-next-line no-undef
                let sortedd = _.sortBy(recom, "label");
                setState((prev) => ({
                    ...prev,
                    modules: sortedd
                }))
                let arr = [];
                let filter = recom.slice(0, 10);
                filter.forEach((e) => {
                    if (
                        e.value.slug !== "macroeconomics" &&
                        e.value.slug !== "microeconomics" &&
                        e.value.slug !== "business" &&
                        e.value.slug !== "marketing" &&
                        e.value.slug !== "our-company" &&
                        e.value.slug !== "useful-articles" &&
                        e.value.slug !== "uncategorized" &&
                        e.value.slug !== "test" &&
                        e.value.slug !== "partners"
                    ) {
                        arr.push(e);
                        arr.sort();
                    }
                });
                // eslint-disable-next-line no-undef
                let sortedTen = _.sortBy(arr, "label");
                for (let i = 0; i < sortedTen.length; i++) {
                    state.sortedData.push(sortedTen[i]);
                }
            }
        }
    }, [studyModulesData]);

    const handleModuleSelect = (value) => {
        setState((prev) => ({
            ...prev,
            selectedModule: value,
        }))
        updateValue((prev) => ({
            ...prev,
            selectedModule: value
        }))
    }
    return (
        <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
            <Stepper step={2} exit={() => history.push("/profile")} totalSteps={4} />
            <Container>
                <div className="pt-10 lg:pt-14 flex">
                    <div className="text-darkest">
                        <p className="tutorialVidText text-36 custom2:text-36 font-bold mb-2 md:mb-4 lg:mb-10">
                            Plan your tutoring Session
                        </p>
                    </div>
                </div>
                <section className="text-gray-600 body-font">
                    <div className="flex flex-col md:flex-row md:space-x-7">
                        <div className="md:w-56 lg:w-80 mb-5">
                            <p className="text-14 mb-4">Please choose the study module</p>
                            <Select
                                placeholder="Select Study Module"
                                values={state.modules}
                                isClearable={false}
                                value={Object.keys(state.selectedModule).length !== 0 ? state.selectedModule : Object.keys(selectedModule).length !== 0 ? selectedModule : ""}
                                handleSelected={handleModuleSelect}
                            />
                        </div>
                    </div>
                    <p className="text-19 mb-11">
                        For best results, in one hour, you should discuss with the tutor
                        only one question in one study module e.g. problem question on
                        negligence
                    </p>
                    <h1 className="text-center text-42">
                        Please select the tutor
                    </h1>
                    <div className="container mx-auto pckgContainer">
                        <div className="flex flex-wrap mt-2 md-mt-8 lg:mt-12 gap-2 mb-6 lg:mb-2 justify-center">
                            {tutor.length &&
                                tutor.map((item, index) =>
                                    item.tutor.map((subItem) => (
                                        <TutorCard
                                            key={subItem.id}
                                            dataItem={subItem}
                                            isSelected={state.selectedTutor === subItem}
                                            onSelect={setState}
                                        />
                                    ))
                                )}
                        </div>
                        <div className="text-center mt-10">
                            {state.selectedTutor !== null && <button
                      type="btn"
                      value="submit"
                      className="bookBtn"
                      onClick={() => {
                        history.push({
                            pathname:  `/book-tutorials/appointment/one-to-one`
                        })
                      }}
                    >
                      Continue
                    </button>}
                  </div>
                    </div>
                </section>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tutor: tutorData(state),
    studyModulesData: studyModulesData(state),

});
const mapDispatchToProps = (dispatch) => ({
    getTutor: (tutorSlug) => dispatch(getTutor(tutorSlug)),
    getStudyModules: () => dispatch(getSpecificStudyModules()),

});

export default connect(mapStateToProps, mapDispatchToProps)(TutoringSelection)