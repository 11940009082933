import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the default styles

function Calendly({ handleDateChange, tutorHolidays }) {
  const [date, setDate] = useState();

  useEffect(() => {
    const dateButton = document.getElementsByClassName('react-calendar__tile react-calendar__month-view__days__day')
    for(let i=0; i<dateButton?.length; i++) {
      if(dateButton[i].disabled === true){
        dateButton[i].style.cursor = "not-allowed"
      }
    }
  },[tutorHolidays])

  return (
    <div className='reactCalender'>
      <Calendar 
        onChange={handleDateChange} 
        value={date} 
        minDate={new Date()} 
        maxDate={new Date('2025-12-31')}
        tileDisabled={({ date, view }) => {
        if (view === 'month') {
          const formattedDate = date.toISOString().split('T')[0];
          const selectedDate = new Date(formattedDate);
          selectedDate.setDate(selectedDate.getDate() + 1);
          const newDate = selectedDate.toISOString().split('T')[0];
          return tutorHolidays?.length > 0 && tutorHolidays.some(disabledDate => disabledDate.date === newDate);
        }
        return false;
      }} 
      />
    </div>
  );
}

export default Calendly;
