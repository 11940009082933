import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "./modalError.css";
import Button from "../EmptyButton";
import information from "../../assets/icons/information.svg";
import { getApiKey } from "../../helpers";
import { useHistory } from "react-router-dom";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import Select from "../../components/Select";

const ErrorHandler = ({
  isOpen,
  closeModal,
  simpleClose,
  title,
  content,
  status,
}) => {
  const [showDrop, setShowDrop] = useState(false);
  const [calendars, setCalendars] = useState([
    {
      value: "google",
      label: "Google Calendar",
    },
    {
      value: "iCal",
      label: "iCalendar",
    },
    {
      value: "yahoo",
      label: "Yahoo",
    },
    {
      value: "outlook",
      label: "Outlook",
    },
  ]);
  const histories = useHistory();

  const customStyle = {
    modal: {
      borderRadius: '25px',
      width: '50%'
    },
  }

  const handleLogin = (e) => {
    // e.preventDefault();
    histories.push("/signup");
    closeModal();
  };

  const loginRoute = () => {
    histories.push("/login");
    closeModal();
  };

  const handleSubs = (e) => {
    e.preventDefault();
    histories.push("/subscription");
  };

  const handleCalendar = () => {
    setShowDrop(true);
  };

  const handleSelectedModule = (item) => {
    let event = JSON.parse(localStorage.getItem("events"));

    for (let j = 0; j < event.length; j++) {
      if (item.value === "google") {
        let url = google(event[j]);
        window.open(url, "_blank");
      }
      if (item.value === "yahoo") {
        let url = yahoo(event[j]);
        window.open(url, "_blank");
      }
      if (item.value === "outlook") {
        let url = outlook(event[j]);
        window.open(url, "_blank");
      }
      if (item.value === "iCal") {
        let url = ics(event[j]);
        const link = document.createElement("a");
        link.href = url;
        link.download = title;
        link.click();
      }
    }
  };

  const handleMyTutorSessions = () => {
    if (window.location.href.includes("appointments_create")) {
      histories.push({ pathname: "/user-sessions", state: "handler" });
    } else {
      histories.push("/user-sessions");
    }
  };

  const handleStatus800 = (value) => {
    if(value === 'unlock'){
      histories.push("/subscription");
    } else if(value === "not"){
      closeModal()
    }
  }

  return (<div className="parent-modal-div">

 
    <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center styles={customStyle}
    
    classNames={{
      overlay: 'custom-overlay-class',
     modal: 'react-modal-class2 errorModalMain', 
    }}>
      <div className="flex flex-row w-full items-center justify-center my-8 absolute -top-10 left-0 ">
        {status !== 204 ? (
          <img src={information} alt="" className="w-14 h-auto" />
        ) : null}
      </div>
      <p className="text-darkest text-large text-center text-h5 md:text-h4 custom2:text-h3 font-bold mb-5">
        {title !== "Error" ? title : null}
      </p>

      <div className="flex flex-row md:text-h5 flex-wrap w-full items-center justify-center">
        <p className="text-center">
          {status === 403 || status === 401 ? (
            <div>
              <p>Log in to proceed</p>
              <br />
              <Button
                filled
                onClick={() => {
                  loginRoute();
                }}
              >
                <p
                  className="text-center text-14 xl:text-body-large"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  OK
                </p>
              </Button>
            </div>
          ) : 
            status === 209 ? <div className="text-14" dangerouslySetInnerHTML={{__html: content}}></div> : content
          }
        </p>
      </div>
      <div className="flex flex-row md:text-h5 flex-wrap w-full items-center justify-center">
        <p className="text-center mt-16">
          {status === 201 ? (
            <Button filled onClick={status === 201 ? closeModal : simpleClose}>
              <p
                className="text-center text-14 xl:text-body-large"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                OK
              </p>
            </Button>
          ) : null}
        </p>
      </div>
      <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
        <div
          className="w-full mt-5 md:mt-0"
          style={status === 211 ? { width: "400px" } : null}
        >
          {status === 403 && status === 401 ? (
            <Button
              filled
              onClick={
                status === 403 || status === 401 ? closeModal : simpleClose
              }
            >
              <p className="text-center text-14 xl:text-body-large">OK</p>
            </Button>
          ) : null}
          {status === 200 && !getApiKey() ? (
            <Button
              filled
              onClick={(e) => (status === 200 ? handleLogin(e) : simpleClose)}
            >
              <p className="text-center text-14 xl:text-body-large">
                Sign Up Now
              </p>
            </Button>
          ) : null}
          {status === 203 ? (
            <div className=" max-w-max m-auto">
            <Button
              filled
              onClick={(e) => (status === 203 ? handleSubs(e) : simpleClose)}
            >
              {/* {window.location.pathname.includes("/study-module/") ? (
                <p className="text-center text-14 xl:text-body-large">
                  Upgrade to the Premium Now
                </p>
              ) : ( */}
                <p className="text-center text-14 xl:text-body-large">
                  Start Your Free Trial Now
                </p>
              {/* )} */}
            </Button>
            </div>
          ) : null}
          {status === 400 && !getApiKey() ? (
            <Button filled onClick={(e) => handleLogin(e)}>
              <p className="text-center t\.ext-14 xl:text-body-large">
                Sign Up Now
              </p>
            </Button>
          ) : null}
          {status === 800 ? (
            <div className="flex space-x-10">
              <Button filled onClick={() => handleStatus800('unlock')}>
                <p className="text-center text-14 xl:text-body-large">
                  Unlock Premium
                </p>
              </Button>
              <Button onClick={() => handleStatus800('not')}>
                <p className="text-center text-14 xl:text-body-large">
                  Not Now
                </p>
              </Button>
            </div>
          ) : (
            ""
          )}
          {status === 700 ? (
            <Button filled onClick={handleMyTutorSessions}>
              <p className="text-center t\.ext-14 xl:text-body-large">
                See your Classes here
              </p>
            </Button>
          ) : null}
          {status === 100 &&
          window.location.href.includes("appointments_create") ? (
            <Button filled onClick={(e) => handleCalendar(e)}>
              <p className="text-center text-14 xl:text-body-large">
                Add to your calendar
              </p>
            </Button>
          ) : null}
          {status === 211 && !getApiKey() ? (
            <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
              <div className="w-56">
                <Button
                  filled
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  <p>Sign Up Now</p>
                </Button>
              </div>
              <div className="w-56 mt-5 md:mt-0">
                <Button
                  onClick={() => {
                    loginRoute();
                  }}
                >
                  <p>Log In</p>
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        {showDrop && (
          <div className="mt-10">
            <Select
              placeholder="Calendar"
              values={calendars}
              handleSelected={handleSelectedModule}
            />
          </div>
        )}
      </div>
    </Modal>
    </div>
  );
};

export default ErrorHandler;
